import { LitElement, css } from 'lit';
import { customElement } from 'lit/decorators.js';

import './pages/app-home';
import './components/header';
import './styles/global.css';
import { router } from './router';

@customElement('app-index')
export class AppIndex extends LitElement {
	static styles = css`
		main {
			padding-left: 0px;
			padding-right: 0px;
			padding-bottom: 0px;
		}
	`;

	firstUpdated() {
		router.addEventListener('route-changed', () => {
			if ('startViewTransition' in document) {
				(document as any).startViewTransition(() =>
					this.requestUpdate()
				);
			} else {
				this.requestUpdate();
			}
		});
	}

	render() {
		// router config can be round in src/router.ts
		return router.render();
	}
}
